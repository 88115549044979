import React from 'react'
import { Link } from 'react-router-dom'
export default function CustomFooter() {
  return (
    <>
    <br/>
        <footer className="bg-lightblue text-center text-black" style={{backgroundColor:'#ECF0F1'}} >
  
  <div className="container p-4 pb-0">
    <section className="mb-4">
      <Link className="btn btn-outline-dark btn-floating m-1" to="/" role="button"
        >Home</Link>
        <Link className="btn btn-outline-dark btn-floating m-1" to="/HotDeals" role="button"
        >HotDeals</Link>

 <Link className="btn btn-outline-dark btn-floating m-1" to="/Contact" role="button"
        >Contact</Link>

 <Link className="btn btn-outline-dark btn-floating m-1" to="/About" role="button"
        >About</Link>
       


    </section>
  </div>

  <div className="text-center p-3" >
  <br/>
    © 2023 Copyright  : All right reserved to Bookingmytrip.org<br/>
    <Link className="text-black" to="/"> Scubadive.org.in</Link><br/>
    <Link className="text-black" to="/Refund"> Refund policy</Link><br/>
    <Link className="text-black" to="/Privacypolicy"> Privacy policy</Link>

    <br/>
    <a href='https://www.termsandconditionsgenerator.com/live.php?token=eXQjBjw13PkfN7pnaJlMsHdfoNG0YXhT' alt='terms and condition'>Terms & Condition</a><br/>
    Mail : support@scubadive.org.in
  </div>
</footer>
</>
    
  )
}
