import React from 'react'
import "./HotDeals.css"


const cardContent = [
  /*cart1*/
  {
    imageUrl: './images/scubadivegoa2.png',
    title: 'Scuba diving in goa + watersports ',
    Book: 'Book Now',
    description: <ol style={{ textAlign: 'justify' }}>
    
      <h3>Package Details</h3>
  <li><b>Complimentary Pickup and Drop Service</b></li>
    Convenient transportation services are available to pick you up from your location and ensure a comfortable return journey.

  <li><b>Grand Island Adventure</b></li>
    The highlight of your excursion, Grand Island is the ultimate destination for an unforgettable experience.

  <li><b>40 Kilometer Sea Voyage</b></li>
    Embark on an exciting 40-kilometer boat trip, offering breathtaking vistas of the expansive sea.

  <li><b>Thrilling White Water Deep-Sea Dive</b></li>
    Dive deep into the ocean for a thrilling underwater adventure, where you may encounter fascinating marine life, including white water species.

  <li><b>Discover Scuba Diving</b></li>
    Immerse yourself in the underwater world through scuba diving, a chance to explore the diverse marine ecosystems.

  <li><b>PADI Certified Scuba Training</b></li>
    New to scuba diving? Receive comprehensive training from a certified PADI instructor to ensure your safety and mastery of diving techniques.

  <li><b>Aerial Views with Parasailing</b></li>
    Soar high above the sea while parasailing, offering breathtaking aerial views of the surrounding beauty.

  <li><b>Jet Skiing Adventure</b></li>
    Feel the rush of adrenaline as you ride a jet ski on the open waters, experiencing pure exhilaration.

  <li><b>Banana Boat Ride</b></li>
    Enjoy a leisurely water activity as you sit on an inflatable banana-shaped boat towed by a speedboat.

  <li><b>Exciting Bumper Ride</b></li>
    Hold on tight for an exhilarating experience as you ride on an inflatable tube, towed by a speedboat, creating a thrilling and bumpy journey.

  <li><b>High-Speed Boat Adventure</b></li>
    For those seeking a high-adrenaline experience, take a ride on a high-speed boat that will get your heart racing.

  <li><b>Underwater Memories with GoPro8</b></li>
    Capture your underwater adventures in high-definition with the GoPro8 camera, preserving your memories for years to come.

  <li><b>Refreshing Beverages and Breakfast</b></li>
    Recharge with refreshing beverages and a hearty breakfast as part of your package.

  <li><b>Delicious Lunch Options (Non-Veg/Veg)</b></li>
    Savor a delectable lunch with a choice of both non-vegetarian and vegetarian dishes.

  <li><b>Quality Equipment Rental</b></li>
    All necessary equipment for your activities, from diving gear to water sports equipment, is available for rent.
</ol>,

    link: 'https://wa.me/917017751245?text=hello%20%2C%20its%20great%20to%20know%20about%20your%20website%20scubadive.org.in%20.%20I%20am%20interested%20for%20booking%20scubadive+watersports%20package%20%2C%20kindly%20send%20me%20the%20details%3F',
  },


/*cart2*/

  {
    imageUrl: './images/watersportsgoa.png',
    title: 'Water sports in goa',
    Book: 'Book Now',

    description: <ol style={{ textAlign: 'justify' }}>
      <h3>Package Details</h3>
      <li><strong>Exciting Water Adventures:</strong> Immerse yourself in a world of thrilling water-based activities in Goa, tailor-made for adventure enthusiasts and water lovers of all ages.</li>

<li><strong>Jet Skiing Thrills:</strong> Experience the ultimate adrenaline rush as you commandeer a high-speed Jet Ski, racing across the waves and feeling the power of the sea beneath you.</li>

<li><strong>Banana Boat Fun:</strong> Climb aboard an inflatable banana-shaped boat for a delightfully bumpy ride, towed by a speedy watercraft, promising laughter and excitement for all aboard.</li>

<li><strong>Bumping on the Tube:</strong> Hang on tight for a heart-pounding adventure as you ride an inflatable tube towed by a speedboat, bouncing over the ocean waves for an exhilarating experience.</li>

<li><strong>High-Speed Sea Adventure:</strong> Feel the thrill of speed as you embark on a high-speed boat ride, with the wind in your hair and picturesque views of Goa's stunning coastline.</li>

<li><strong>Skyward Parasailing:</strong> Soar high above the pristine Arabian Sea, taking in breathtaking vistas of Goa's coastline while securely attached to a parachute and towed by a boat, creating unforgettable memories.</li>

<li><strong>Expert Instructors:</strong> Our team of seasoned and certified instructors is dedicated to ensuring your safety and providing expert guidance for each water activity, ensuring a memorable and secure experience.</li>

<li><strong>Life Jackets for Safety:</strong> Safety always comes first! We offer comfortable life jackets to guarantee your buoyancy and safety throughout your water adventures.</li>

<li><strong>Convenient Equipment Rentals:</strong> No need to lug around your gear; we offer convenient equipment rental services, providing you with everything required for an exciting day of water sports in Goa.</li>

    </ol>,
    link: 'https://wa.me/917017751245?text=hello%20%2C%20its%20great%20to%20know%20about%20your%20website%20scubadive.org.in%20.%20I%20am%20interested%20for%20booking%20watersports%20package%20%2C%20kindly%20send%20me%20the%20details%3F',

  },

/*cart3*/
  {
    imageUrl: './images/adventureboatparty.avif',
    title: 'Adventure cruise in goa + water sports',
    Book: 'Book Now',

    description: <ol style={{ textAlign: 'justify' }}>
      <h3>Package Details</h3>

      <li><strong>Flexible Time Slots:</strong> Choose your preferred time slot - either the refreshing <em>Morning Slot from 9:00 AM to 12:00 PM</em> or the relaxing <em>Evening Slot from 1:00 PM to 4:30 PM</em>.</li>

<li><strong>Convenient Pick Up and Drop:</strong> Enjoy the ease of transportation with complimentary pick-up and drop-off services from your location, ensuring a hassle-free experience.</li>

<li><strong>Welcome Beers:</strong> Kickstart your adventure with a warm welcome - savor two complimentary beers as you embark on your journey.</li>

<li><strong>Delectable Buffet Lunch:</strong> Treat your taste buds to a delightful buffet lunch featuring an array of mouthwatering options, both vegetarian and non-vegetarian.</li>

<li><strong>Refreshing Soft Beverages:</strong> Stay revitalized throughout your adventure with complimentary soft drinks, quenching your thirst on your exciting journey.</li>

<li><strong>Sightseeing at Atal Setu Bridge:</strong> Experience the awe-inspiring beauty of the Mandovi River as you journey beneath the magnificent Atal Setu Bridge, a scenic delight for your eyes.</li>

<li><strong>Kayaking Expedition:</strong> Explore the serene waters of Goa with an unforgettable kayaking experience, immersing yourself in the natural splendor that surrounds you.</li>

<li><strong>Leisurely Swimming:</strong> Take a refreshing dip in the crystal-clear waters, providing a tranquil and relaxing break during your adventure.</li>

<li><strong>Banana Boat Thrills:</strong> Hold on tight and brace yourself for an exhilarating ride on an inflatable banana-shaped boat, promising thrills and spills on the water.</li>

<li><strong>Bumpy Bumper Ride:</strong> Embark on an exciting and bumpy journey as you ride an inflatable tube towed by a speedboat, creating unforgettable memories.</li>

<li><strong>Jet Skiing Excitement:</strong> Feel your heart race as you skim across the waves on a high-speed Jet Ski, a thrilling experience on the water.</li>

<li><strong>High-Speed Adventure Boat:</strong> Enjoy the rush of the wind in your hair and the stunning coastal views on a high-speed boat ride, adding a touch of excitement to your day.</li>

<li><strong>Safety Gear Included:</strong> Your safety is our priority! We provide all necessary equipment, including life jackets, to ensure your safety during your scuba diving adventure.</li>


    </ol>,
    link: 'https://wa.me/917017751245?text=hello%20%2C%20its%20great%20to%20know%20about%20your%20website%20scubadive.org.in%20.%20I%20am%20interested%20for%20booking%20Adventure%20watersports%20package%20%2C%20kindly%20send%20me%20the%20details%3F',

  },
/*cart4*/
  {
    imageUrl: './images/dinnercruisegoa.webp',
    title: 'Dinner cruise in goa',
    Book: 'Book Now',

    description: <ol style={{ textAlign: 'justify' }}>
      <h3>Package Details</h3>
      
      <li><strong>Cruise Schedule:</strong> Set sail from 8:30 PM to 11:30 PM for an enchanting evening on the Mandovi River.</li>

<li><strong>Complimentary Pick-Up and Drop-Off:</strong> Enjoy the convenience of door-to-door transportation, ensuring a seamless journey to and from your location.</li>

<li><strong>2-Hour Scenic River Cruise:</strong> Embark on a picturesque and leisurely cruise along the serene Mandovi River, taking in the beauty of the Goan night.</li>

<li><strong>Live DJ Entertainment:</strong> Groove to the rhythmic beats of a live DJ, adding a vibrant and energetic atmosphere to your cruise experience.</li>

<li><strong>Air-Conditioned Dance Floor:</strong> Dance the night away in comfort on the air-conditioned dance floor, where the party never stops.</li>

<li><strong>Sumptuous Buffet Dinner:</strong> Indulge your palate with a sumptuous buffet dinner featuring a diverse selection of both vegetarian and non-vegetarian culinary delights.</li>

<li><strong>Choice of Two Refreshing Drinks:</strong> Sip on your choice of two beverages, whether it's a refreshing non-alcoholic option or a spirited alcoholic libation.</li>

<li><strong>Enchanting Folk Performances:</strong> Be captivated by mesmerizing folk dance performances that will add cultural charm to your cruise experience.</li>



    </ol>,
    link: 'https://wa.me/917017751245?text=hello%20%2C%20its%20great%20to%20know%20about%20your%20website%20scubadive.org.in%20.%20I%20am%20interested%20for%20booking%20dinner+cruise%20package%20%2C%20kindly%20send%20me%20the%20details%3F',

  },
/*cart5*/
  {
    imageUrl: './images/dudhsagar.jpeg',
    title: 'Dudh sagar waterfall package',
    Book: 'Book Now',

    description: <ol style={{ textAlign: 'justify' }}>
      <h3>Package Details</h3>
      
      <li><strong>Experience Location:</strong> Dudhsagar, Kulem</li>
  <li><strong>Tour Hours:</strong> 06:00 AM - 05:00 PM (Group Tour)</li>
  <li><strong>Duration of Adventure:</strong> 11 Hours (Group Tours)</li>
  <li><strong>Pick-up Points:</strong> Convenient pick-up locations include Candolim, Baga, Calangute, and Panjim.</li>
  <li><strong>Pick-up & Drop-off:</strong> We'll pick you up and drop you off right at your hotel location.</li>
  <li><strong>Group Size:</strong> For our group tours (excluding peak dates), we maintain a cozy batch size of 20-30 members.</li>


<h3>Discover Dudhsagar Falls and Spice Plantation:</h3>
<p>Escape the hustle and bustle of city life and embark on an extraordinary journey to Dudhsagar Falls and a Spice Plantation, an ideal adventure for you and your loved ones. Located near Kulem on the Mandovi River, this awe-inspiring waterfall is nestled within lush forests, offering a thrilling opportunity for nature lovers to reconnect with the natural world. Additionally, the Spice Plantation tour in Keri provides valuable insights into a variety of spices. It's the perfect getaway to immerse yourself in the beauty of the forest and waterfalls for an entire day.</p>

<h3>Highlights of the Experience:</h3>
<p>Begin your Dudhsagar Falls and Spice Plantation adventure with a comfortable pick-up from the nearest landmark, whether you're in Candolim, Baga, Calangute, or at the Panjim bus stand. Our shared AC vehicle will be ready to go at around 6:00 AM. Embark on an exhilarating 12 KM jeep safari through Mollem National Park, leading you to the breathtaking Dudhsagar waterfalls.</p>
<p>Take a refreshing dip in the cascading waters, secured with a provided life jacket, and make lasting memories with your companions. Continue your day with a guided tour of a spice plantation, with the option to enjoy an elephant ride at an additional cost.</p>
<p>Indulge in the rich flavors of Goan cuisine and delight your taste buds with a delectable veg/non-veg lunch, thoughtfully included in your Dudhsagar Falls and Spice Plantation adventure. Conclude your unforgettable journey with a complimentary Old Goa Tour, where you'll explore various churches and UNESCO World Heritage Sites.</p>


    </ol>,
    link: 'https://wa.me/917017751245?text=hello%20%2C%20its%20great%20to%20know%20about%20your%20website%20scubadive.org.in%20.%20I%20am%20interested%20for%20booking%20dudhsagar%20package%20%2C%20kindly%20send%20me%20the%20details%3F',

  },
/*cart6*/
  {
    imageUrl: './images/flyboarding.webp',
    title: 'Fly boarding in goa',
    Book: 'Book Now',

    description: <ol style={{ textAlign: 'justify' }}>
      <h3>Package Details</h3>
     
      <li><strong>Adventure Locations:</strong> Discover the thrill of Flyboarding at both Chapora River and the stunning Calangute Beach in Goa.</li>
  <li><strong>Adventure Hours:</strong> Experience the excitement from 09:00 AM to 5:00 PM.</li>
  <li><strong>Adventure Duration:</strong> Your Flyboarding adventure lasts approximately 35 minutes, which includes training sessions and your exhilarating flight.</li>
  <li><strong>Flight Time:</strong> Enjoy around 15 minutes of pure flight as you soar above the water's surface.</li>


<h3>Experience Flyboarding in North Goa:</h3>
<p>Goa, a paradise for adventure seekers, is renowned for its azure waters and is a premier destination for the thrilling sport of flyboarding. Prepare for an adrenaline rush like no other as you are propelled into the air by hydro-powered flight, offering awe-inspiring views of either Calangute Beach or the tranquil Chapora River. This exhilarating activity is conducted under the watchful eye of certified trainers, ensuring that your flyboarding experience in Goa at Calangute Beach and Chapora River is both safe and unforgettable.</p>

<h3>What to Expect during the Flyboarding Activity:</h3>
<p>Arrive at the adventure location 15 minutes before your scheduled start time for a seamless experience. Following a thorough briefing from our expert instructors, get ready for a heart-pounding Goa flyboarding adventure. You'll be equipped with jet propulsion, a safety helmet, and a life jacket, ensuring your safety throughout the experience.</p>
<p>Connected by a long hose, the flyboard propels riders to heights of approximately 30 feet above the water's surface. Feel the rush of excitement as you glide above the sea for an impressive 10 to 15 minutes. The hydro-powered flight is skillfully controlled by a trained instructor, guaranteeing a safe and enjoyable experience.</p>
<p>Furthermore, the jet propeller allows you to dive into the pristine waters, giving you the opportunity to explore the depths below, adding another layer of excitement to your adventure.</p>

    </ol>,
    link: 'https://wa.me/917017751245?text=hello%20%2C%20its%20great%20to%20know%20about%20your%20website%20scubadive.org.in%20.%20I%20am%20interested%20for%20booking%20flyboarding%20package%20%2C%20kindly%20send%20me%20the%20details%3F',

  },
/*cart7*/
  {
    imageUrl: './images/img18.jpg',
    title: 'bungee jumping in goa',
    Book: 'Book Now',

    description: <ol style={{ textAlign: 'justify' }}>
      <h3>Package Details</h3>
      <li><strong>Adventure Location:</strong> Discover the thrill in Vadaval, near Sal, with the exact coordinates at 403504.</li>
<li><strong>Participant Age:</strong> This adventure welcomes participants aged from 12 to 50 years. If you're above 50, a valid medical certificate is required to ensure your safety.</li>
<li><strong>Adventure Hours:</strong> The excitement awaits you from 10:00 AM to 4:30 PM, providing ample time for your thrilling experience.</li>
<li><strong>Recommended Attire:</strong> For your safety and comfort, we recommend wearing shoes and trousers during the adventure.</li>
<li><strong>Package Inclusions:</strong> Your adventure package includes thrilling DSLR video and photos to capture your memories, along with an official jump certificate to commemorate your achievement.</li>
<li><strong>Optional Drone Shot:</strong> Enhance your experience with a breathtaking drone shot, available for an additional cost of Rs 999, if you choose to add this unique perspective to your adventure.</li>

    </ol>,
    link: 'https://wa.me/917017751245?text=hello%20%2C%20its%20great%20to%20know%20about%20your%20website%20scubadive.org.in%20.%20I%20am%20interested%20for%20booking%20bungeejumping%20package%20%2C%20kindly%20send%20me%20the%20details%3F',

  },
  /*cart8*/
  {
    imageUrl: './images/img19.jpg',
    title: 'North goa sightseeing package',
    Book: 'Book Now',

    description: <ol style={{ textAlign: 'justify' }}>
      <h3>Package Details</h3>
      <li><b>**Morning Pickup at 8:30 AM**</b> from the designated pickup points in <b>Panjim</b>, <b>Calangute</b>, <b>Baga</b>, <b>Arpora</b>, <b>Candolim</b>, and <b>Senquerim</b>.</li>

<li><b>**CALANGUTE TO FORT AGUADA:**</b>
Begin your journey with a visit to the historic <b>Fort Aguada</b> in Candolim. This 17th-century fort has gained further fame due to the presence of the luxurious Taj hotel complex. Fort Aguada is not only one of the most significant forts in Goa but also a crucial one in its history. Today, it serves as a Goa jail, but it was originally built by the Portuguese in 1612 to safeguard Old Goa from enemy attacks. The fort's spring supplied water to all the ships that anchored there.
</li>

<li><b>**SALIGAO CHURCH:**</b>
Admire the distinctive architectural design of <b>Saligao Church</b>, which is beautifully illuminated during the tourist season. Located just 4 kilometers from the renowned Calangute beach, this church serves as the Parish Church for the Saligao village. The Goa Tourism Department has thoughtfully arranged for the church to be illuminated at night, creating a radiant jewel amidst the fields along the Chogum road from Panjim to Calangute.
</li>

<li><b>**CALANGUTE BEACH:**</b>
Conclude your tour with a visit to <b>Calangute Beach</b>, often referred to as the queen of Goa's beaches. This beach is a hub of picnics and musical performances, drawing crowds of visitors. Calangute is also a hub for various water sports activities, providing entertainment for beachgoers. The beach boasts a range of luxury and budget hotels, making it a favored destination for travelers from around the world.
</li>

<li><b>**ANJUNA BEACH (Viewpoint):**</b>
Explore <b>Anjuna Beach</b>, a popular spot among foreigners, known for its picturesque beauty, Trance parties, and the Hippie culture that thrived in the 60s and 70s. Anjuna Beach is famous for its vibrant nightlife and is always bustling with foreign tourists. One of the highlights here is the Wednesday market, widely recognized as the "Flea Market."
</li>

<li><b>**VAGATOR BEACH:**</b>
Discover <b>Vagator Beach</b>, celebrated for its secluded charm, with the magnificent Chapora Fort serving as a majestic backdrop. This beach is undeniably one of the world's finest. Vagator Beach is divided into two main sections by a seaside headland, offering a variety of stalls selling trinkets, clothing, soft drinks, and snacks. As you face the sea, you'll find North Vagator Beach (Big Vagator) on your right and Ozram Beach, also known as Little Vagator Beach, on your left.
</li>

<li><b>**MORJIM BEACH:**</b>
Experience the tranquility of <b>Morjim Beach</b>, an isolated gem in North Goa that attracts a large number of Russian tourists. The beach features vast stretches of pristine sand framed by palm trees and casuarina groves. Keep an eye out for beautiful bird sightings in this serene environment.
</li>

<li><b>**ASHVEM BEACH:**</b>
Wrap up your exploration at <b>Ashvem Beach</b>, a rocky yet picturesque beach in northern Goa. This beach is a favorite among locals for family picnics.
</li>

<li>Return to your hotel by <b>4:30 PM</b>.</li>


    </ol>,
    link: 'https://wa.me/917017751245?text=hello%20%2C%20its%20great%20to%20know%20about%20your%20website%20scubadive.org.in%20.%20I%20am%20interested%20for%20booking%20north+goa+sightseeing%20package%20%2C%20kindly%20send%20me%20the%20details%3F',

  },

  /*cart9*/
  {
    imageUrl: './images/img21.jpg',
    title: 'South goa sightseeing in goa',
    Book: 'Book Now',

    description: <ol style={{ textAlign: 'justify' }}>
      <h3>Package Details</h3>
      <li><b>**Pickup Timing**: 08:30 AM to 09:30 AM</b> at the designated Calangute pickup point nearest to your hotel or resort.</li>

<li><b>**CALANGUTE TO MIRAMAR BEACH:**</b>
  Begin your journey with a visit to the charming <b>Miramar Beach</b>, where you'll find the Samadhi of Goa's first Chief Minister, Late Dayananda Bandodkar. Situated on the route to Dona Paula and just 3 km from Panjim, the capital city of Goa, this beach is affectionately known as "Gasper Dias."
</li>

<li><b>**DONA PAULA:**</b>
  At Dona Paula, soak in the breathtaking view from its vantage point, where the River Zuari gracefully merges with the Arabian Sea. Dona Paula Beach, nestled on the Arabian Sea, is a secluded bay and one of Goa's most sought-after beaches. It's a hub for water sports enthusiasts. Once a tranquil village, it has now transformed into one of Goa's most vibrant areas. The beach derives its name from Dona Paula de Menzes, the daughter of one of India's Viceroys, who, according to legend, leaped off the cliff as she couldn't marry a local fisherman. This beach exudes mysticism and natural beauty.
</li>

<li><b>**BOM JESUS OF BASILICA (Old Goa Churches):**</b>
  Explore the Basilica of Bom Jesus, where the remains of St. Francis Xavier are interred. This basilica holds the prestigious title of being a World Heritage Monument and stands as one of Goa's finest examples of baroque architecture. The name of Jesus is symbolically represented by the letters 'IHS,' which adorn the Basilica throughout.
</li>

<li><b>**ST. CATHEDRAL:**</b>
  Visit the St. Cathedral, a magnificent 16th-century religious edifice and one of Goa's most ancient and revered buildings. It stands as a testament to Roman Catholic rule in Portuguese Goa and holds the distinction of being the largest church in Asia and the East.
</li>

<li><b>**MANGUESHI TEMPLE:**</b>
  Pay homage to Lord Shiva at the Mangueshi Temple, a sacred site constructed in the 17th Century.
</li>

<li><b>**SHANTADURGA TEMPLE:**</b>
  Explore the opulent Shantadurga Temple, dedicated to the goddess of peace, Shantadurga. This temple is renowned for its golden palanquin.
</li>

<li><b>**RIVER CRUISE (BOAT CRUISE):**</b>
  Immerse yourself in Goa's vibrant culture with songs and dance during an enjoyable river cruise.
</li>

<li>Conclude your day with a drop-off at your hotel, expected by <b>7:00 PM</b>.</li>

    </ol>,
    link: 'https://wa.me/917017751245?text=hello%20%2C%20its%20great%20to%20know%20about%20your%20website%20scubadive.org.in%20.%20I%20am%20interested%20for%20booking%20south+goa+sightseeing%20package%20%2C%20kindly%20send%20me%20the%20details%3F',

  },

 /*cart end here*/




];

function Whatsapp() {
  return (
    <div>
      
      <div className="container mt-4">
        <div className="row">
          {cardContent.map((card, index) => (
            <div className="col-lg-4 col-md-6 mb-4" key={index}>
              <div className="card">
                <div className="position-relative">
                  <img src={card.imageUrl} className="card-img-top" alt="scuba dive in goa" />
                  <div className="overlay"></div>
                </div>
                <div className="card-body  custom-center">
                  <h5 className="card-title">
                    {card.title} <hr /><h6>Booking Amount : 500 INR/Person</h6><hr />
                    <a onclick="goog_report_conversion ('href={card.link}')" href={card.link} className="btn btn-primary" style={{ backgroundColor: 'blue ' }}>{card.Book}</a>

                  </h5>
                  <div className="card-text custom-scrollbar">
                    {card.description}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Whatsapp;















