import React from 'react';
import Home from './components/Home';
import { Route, Routes } from "react-router-dom";
import Contact from './components/Contact';
import About from './components/About';
import CustomNavbar from './components/CustomNavbar';
import CustomFooter from './components/CustomFooter';
import "./App.css"
import Refund from './components/Refund';
import Privacypolicy from './components/Privacypolicy';
import HotDeals from './components/HotDeals';
import Payment from './components/Payment';


const App = () => {
	return (
		<div className='container'>
         
		
		 <CustomNavbar/>
				<Routes>
                     
					<Route exact path="/" element={<Home/> } />
					<Route exact path="/Contact" element={<Contact/>} />
                    <Route exact path="/About" element={<About/> } />
					<Route exact path="*" element={<Home/>} />
					<Route exact path="/Refund" element={<Refund/>} />
					<Route exact path="/Privacypolicy" element={<Privacypolicy/>} />
					<Route exact path="/HotDeals" element={<HotDeals/>} />
					<Route exact path="/Payment" element={<Payment/>} />



				</Routes>
				<CustomFooter/>
			

		</div>
		
	);
};
export default App;






