import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import img from './images/car3.jpg'

const Container = styled.div`
  position: relative;
  text-align: center;
  color: white; /* Set text color to white */
`;

const BackgroundImage = styled.img`
  width: 100%;
  height: auto;
`;

const TextContainer = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Text = styled.h1`
  font-size: 22px; /* Increase the font size */
  font-weight: bold;
`;
const SmallH2 = styled.h2`
  font-size: 16px; /* Set the desired font size for h2 */
`;



const initialText = 'SCUBA DIVING IN GOA + WATER SPORTS IN GOA + DINNER CRUISE OR DUDHSAGAR IN GOA';

const Background= () => {
  const [currentText, setCurrentText] = useState(initialText);

  useEffect(() => {
    const delay = 100; // Delay between each letter
    let currentIndex = 0;

    const writeText = () => {
      if (currentIndex <= initialText.length) {
        setCurrentText(initialText.substring(0, currentIndex));
        currentIndex++;
        setTimeout(writeText, delay);
      }
    };

    writeText();
  }, []);

  return (
    <Container>
      <BackgroundImage src={img} alt="scuba diving in Goa" />
      <TextContainer>
        <Text>{currentText}</Text>
        <SmallH2>GET 30% OFF ON SCUBA DIVE AND WATER SPORTS PACKAGES</SmallH2>
        
 <a onclick="goog_report_conversion ('https://wa.me/917017751245?text=hello%20%2C%20its%20great%20to%20know%20about%20your%20website%20scubadive.org.in%20.%20I%20am%20interested%20for%20booking%20'scubadive%20and%20+watersports%20package'%20%2C%20kindly%20send%20me%20the%20details%3F')"
                                    href="https://wa.me/917017751245?text=hello%20%2C%20its%20great%20to%20know%20about%20your%20website%20scubadive.org.in%20.%20I%20am%20interested%20for%20booking%20'scubadive%20and%20+watersports%20package'%20%2C%20kindly%20send%20me%20the%20details%3F" className="btn btn-primary" style={{ backgroundColor: 'blue ' }}>Book Now</a>


      </TextContainer>
    </Container>
  );
};

export default Background;
