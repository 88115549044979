import React from 'react'

function Privacypolicy() {
  return (
    <div>
        <p>
       <h2>Privacy Policy</h2> <hr/>

At ScubaDive.org.in, we are committed to protecting the privacy of our website visitors. This Privacy Policy outlines the types of personal information we may collect, how we use and protect that information, and your choices regarding your personal information.<hr/>

Information We Collect<hr/>

We may collect the following types of personal information from you:<br/>

    Contact Information: We may collect your name, email address, and phone number if you contact us through our website or sign up for our newsletter.<br/>

    Payment Information: If you make a payment through our website, we may collect your billing address, credit card number, and expiration date.<br/>

    Usage Information: We may collect information about how you use our website, such as the pages you visit, the links you click on, and the search terms you use.<br/><hr/>

How We Use Your Information<hr/>

We may use your personal information for the following purposes:<br/>

    To provide our services to you.<br></br>

    To communicate with you about our services and promotions.<br/>

    To improve our website and services.<br/>

    To process payments.<br/>

    To comply with legal requirements.<br/>

We will not sell, rent, or share your personal information with third parties, except as necessary to provide our services to you or as required by law.<hr/>

Security of Your Information<hr/>

We take reasonable measures to protect your personal information from unauthorized access, use, and disclosure. However, no method of transmission over the internet or electronic storage is completely secure. Therefore, we cannot guarantee the absolute security of your personal information.<hr/>

Your Choices<hr/>

You may choose not to provide us with certain personal information, but this may limit your ability to use some of our website's features and services.<br/>

You may also opt-out of receiving promotional emails from us by following the unsubscribe instructions included in each email.<hr/>

Changes to Our Privacy Policy<hr/>

We may update this Privacy Policy from time to time. We will post any changes on this page and, if the changes are significant, we will provide a more prominent notice.<hr/>

Contact Us<hr/>

If you have any questions about this Privacy Policy, please contact us at support@scubadive.org.in

policy : For any mishappining or injury during activites or boat activites , we are not responsible at all . 
        </p>
    </div>
  )
}

export default Privacypolicy