import React from 'react'
import Whatsapp from './Whatsapp';
import Background from './Background';


export default function Home() {
  return (
    <>
     <Background/>
		 <Whatsapp/>
		<br/>
			
    </>
  )
}
