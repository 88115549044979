import React from 'react'
import "./HotDeals.css"
import Scubabg from './Scubabg';


const cardContent = [
  /*cart1*/
  {
    imageUrl: './images/car1.jpg',
    title: 'Scuba diving in goa + watersports ',
    Book: 'Book Now',
    description: <ol style={{ textAlign: 'justify' }}>
      <h3>Package Details</h3>

      <li><b><b>Free Pickup/Drop</b></b></li>
      Convenient transportation services to pick you up from and drop you off at your location.
      <li><b>Trip to Grand Island</b></li>
      This is the main attraction and destination
      of the trip.

      <li><b>40 Km Sea Trip</b></li>
      A boat trip covering a distance of 40 kilometers, likely offering beautiful views of the sea.

      <li><b>White Water Deep-Sea Dive</b></li>
      A thrilling deep-sea diving experience, possibly involving encounters with white water species.

      <li><b>Scuba Diving</b></li>
      Another underwater adventure where participants can explore the marine life.

      <li><b>Scuba Training by PADI Certified Trainer</b></li>
      For those new to scuba diving, this training will be provided by a certified PADI instructor to ensure safety and proper diving techniques.

      <li><b>Parasailing</b></li>
      An exciting activity where participants are towed behind a boat while attached to a parachute, providing stunning aerial views.

      <li><b>Jet Ski</b></li>
      The opportunity to ride a jet ski on the open waters for adrenaline-pumping fun.

      <li><b>Banana Ride</b></li>
      A recreational water activity where participants sit on an inflatable banana-shaped boat towed by a speedboat.

      <li><b>Bumper Ride</b></li>
      Another water adventure involving riding on an inflatable tube that's towed by a speedboat, providing a bumpy and thrilling experience.

      <li><b>Speed Boat</b></li>
      A high-speed boat ride for those seeking an exhilarating and fast-paced experience.

      <li><b>Underwater HD Video GoPro8</b></li>
      Capture your underwater adventures with an HD GoPro8 camera, ensuring you have lasting memories of your trip.



      <li><b>Beers, Water, Breakfast</b></li>
      Refreshments and breakfast are provided as part of the package.

      <li><b>Delicious Lunch Non-Veg/Veg</b></li>
      Enjoy a tasty lunch with both non-vegetarian and vegetarian options.

      <li><b>Equipment Rental</b></li>
      The necessary equipment for various activities, such as diving gear or water sports equipment, is available for rental.

    </ol>,
    link: 'https://wa.me/918171669057?text=hello%20%2C%20its%20great%20to%20know%20about%20your%20website%20scubadive.org.in%20.%20I%20am%20interested%20for%20booking%20scubadive+watersports%20package%20%2C%20kindly%20send%20me%20the%20details%3F',
  },


/*cart2*/

  {
    imageUrl: './images/img8.jpg',
    title: 'Water sports in goa',
    Book: 'Book Now',

    description: <ol style={{ textAlign: 'justify' }}>
      <h3>Package Details</h3>
      <li><strong>Watersports:</strong> Experience a thrilling array of water-based activities in Goa, perfect for adventure enthusiasts and water lovers of all ages.</li>

      <li><strong>Jet Ski:</strong> Zoom across the waves on a high-speed Jet Ski, feeling the adrenaline rush as you control the waters beneath you.</li>

      <li><strong>Banana Ride:</strong> Hop on an inflatable banana-shaped boat and get ready for a fun-filled, bumpy ride as you're towed by a speedboat.</li>

      <li><strong>Bumper Ride:</strong> Hold on tight for a thrilling adventure as you ride on an inflatable tube towed by a speedboat, bouncing over the waves.</li>

      <li><strong>Speed Boat:</strong> Experience the excitement of a high-speed boat ride, with the wind in your hair and scenic views of Goa's coastline.</li>

      <li><strong>Parasailing:</strong> Soar high above the Arabian Sea, enjoying breathtaking views of Goa's beaches while safely harnessed to a parachute and towed by a boat.</li>

      <li><strong>Instructor:</strong> Our experienced and certified instructors will ensure your safety and guide you through each water activity, making it a memorable and secure experience.</li>

      <li><strong>Life Jacket:</strong> Safety first! We provide life jackets to ensure your buoyancy and safety during all water activities.</li>

      <li><strong>Equipment Rental:</strong> Don't worry about bringing your gear; we offer equipment rental services, so you have everything you need for a fantastic day of water sports in Goa.</li>

    </ol>,
    link: 'https://wa.me/918171669057?text=hello%20%2C%20its%20great%20to%20know%20about%20your%20website%20scubadive.org.in%20.%20I%20am%20interested%20for%20booking%20watersports%20package%20%2C%20kindly%20send%20me%20the%20details%3F',

  },

/*cart3*/
  {
    imageUrl: './images/arushi.avif',
    title: 'Adventure cruise in goa + water sports',
    Book: 'Book Now',

    description: <ol style={{ textAlign: 'justify' }}>
      <h3>Package Details</h3>

      <li><strong>Morning Slot:</strong> 9:00 AM to 12:00 PM</li>
      <li><strong>Evening Slot:</strong> 1:00 PM to 4:30 PM</li>
      <li><strong>Free Pick Up and Drop:</strong> Enjoy convenient transportation to and from your location.</li>
      <li><strong>Complimentary Beers:</strong> Start your adventure with 2 complimentary beers.</li>
      <li><strong>Buffet Lunch:</strong> Savor a delicious buffet lunch with both veg and non-veg options.</li>
      <li><strong>Refreshing Soft Drinks:</strong> Stay refreshed with complimentary soft drinks.</li>
      <li><strong>Sightseeing at Atal Setu Bridge:</strong> Take in the scenic views along the Mandovi River and explore the magnificent Atal Setu Bridge.</li>
      <li><strong>Kayaking:</strong> Paddle through the serene waters of Goa, enjoying the natural beauty.</li>
      <li><strong>Swimming:</strong> Dive into the clear waters for a relaxing swim.</li>
      <li><strong>Banana Ride:</strong> Hop on an inflatable banana-shaped boat for a thrilling water ride.</li>
      <li><strong>Bumper Ride:</strong> Experience an exciting and bumpy ride on an inflatable tube towed by a speedboat.</li>
      <li><strong>Jet Ski Ride:</strong> Feel the adrenaline rush as you speed across the waves on a Jet Ski.</li>
      <li><strong>Speed Boat:</strong> Enjoy a high-speed boat ride with the wind in your hair and beautiful coastal views.</li>
      <li><strong>Life Jacket and Equipment:</strong> Safety first! All necessary equipment, including life jackets, is included for your scuba diving adventure.</li>


    </ol>,
    link: 'https://wa.me/918171669057?text=hello%20%2C%20its%20great%20to%20know%20about%20your%20website%20scubadive.org.in%20.%20I%20am%20interested%20for%20booking%20Adventure%20watersports%20package%20%2C%20kindly%20send%20me%20the%20details%3F',

  },
/*cart4*/
  {
    imageUrl: './images/arushi2.webp',
    title: 'Dinner cruise in goa',
    Book: 'Book Now',

    description: <ol style={{ textAlign: 'justify' }}>
      <h3>Package Details</h3>
      
  <li><strong>Cruise Timing:</strong> 8:30 PM to 11:30 PM</li>
  <li><strong>Free Pick Up and Drop:</strong> Enjoy complimentary transportation to and from your location.</li>
  <li><strong>2-Hour Cruise Ride in Mandovi River:</strong> Experience a scenic and leisurely cruise along the Mandovi River.</li>
  <li><strong>Live DJ:</strong> Dance to the beats of a live DJ, adding to the vibrant atmosphere.</li>
  <li><strong>AC Dance Floor:</strong> Enjoy dancing in a comfortable air-conditioned dance floor.</li>
  <li><strong>Buffet Dinner:</strong> Relish a delectable buffet dinner with options for both vegetarian and non-vegetarian dishes.</li>
  <li><strong>2 Drinks:</strong> You have the choice of 2 drinks, including alcoholic and non-alcoholic options.</li>
  <li><strong>Folk Dance:</strong> Be entertained by captivating folk dance performances during the cruise.</li>



    </ol>,
    link: 'https://wa.me/918171669057?text=hello%20%2C%20its%20great%20to%20know%20about%20your%20website%20scubadive.org.in%20.%20I%20am%20interested%20for%20booking%20dinner+cruise%20package%20%2C%20kindly%20send%20me%20the%20details%3F',

  },
/*cart5*/
  {
    imageUrl: './images/img0.jpg',
    title: 'Dudh sagar waterfall package',
    Book: 'Book Now',

    description: <ol style={{ textAlign: 'justify' }}>
      <h3>Package Details</h3>
      
  <li><strong>Activity Location:</strong> Dudhsagar, Kulem</li>
  <li><strong>Activity Timings:</strong> 06:00 AM - 05:00 PM (For Group Tour)</li>
  <li><strong>Activity Duration:</strong> 11 Hours (For Group Tours)</li>
  <li><strong>Pick-up Locations:</strong> Candolim, Baga, Calangute, and Panjim</li>
  <li><strong>Pick-up & Drop-off Location:</strong> Your Hotel location</li>
  <li><strong>Batch size:</strong> 20-30 members per batch in a group tour (excluding peak dates).</li>


<h3>About Dudhsagar Falls and Spice Plantation Tour:</h3>
<p>Take a break from bustling city life and embark on an incredible Dudhsagar Falls and Spice Plantation tour with your loved ones. Located on the Mandovi river near Kulem, this majestic waterfall is surrounded by lush forested areas, offering nature enthusiasts an exciting opportunity to connect with nature. Additionally, you'll gain valuable insights into various spice varieties during a Spice Plantation tour in Keri. It's the perfect escape to spend your entire day in the midst of verdant forests and waterfalls.</p>

<h3>About the Activity:</h3>
<p>Commence your Dudhsagar Falls and Spice Plantation tour by being picked up from the nearest landmark at Candolim, Baga, Calangute, or Panjim bus stand at approximately 6:00 AM in a shared AC vehicle. Embark on a thrilling 12 KM jeep safari through Mollem National Park to reach Dudhsagar waterfalls.</p>
<p>Enjoy a rejuvenating dip in the waterfalls with a provided life jacket and have a fun-filled time with your companions. Continue your day with a guided spice plantation tour and the option for an elephant ride at an additional cost.</p>
<p>Savor the flavors of Goan cuisine and tantalize your taste buds with a veg/non-veg lunch included in the Dudhsagar Falls and Spice Plantation tour package. Wrap up your memorable trip with a complimentary Old Goa Tour, where you'll explore various churches and UNESCO world heritage sites.</p>


    </ol>,
    link: 'https://wa.me/918171669057?text=hello%20%2C%20its%20great%20to%20know%20about%20your%20website%20scubadive.org.in%20.%20I%20am%20interested%20for%20booking%20dudhsagar%20package%20%2C%20kindly%20send%20me%20the%20details%3F',

  },
/*cart6*/
  {
    imageUrl: './images/img16.jpg',
    title: 'Fly boarding in goa',
    Book: 'Book Now',

    description: <ol style={{ textAlign: 'justify' }}>
      <h3>Package Details</h3>
     
  <li><strong>Activity Location:</strong> Chapora River, Goa & Calangute Beach, Goa</li>
  <li><strong>Activity Timings:</strong> 09:00 AM to 5:00 PM</li>
  <li><strong>Activity Duration:</strong> 35 Minutes (Including Training Sessions + Flight)</li>
  <li><strong>Flight Duration:</strong> Approximately 15 Minutes</li>


<h3>About Flyboarding in North Goa:</h3>
<p>Goa is a haven for adventure enthusiasts, renowned for its captivating azure waters and a top destination for flyboarding. Experience the thrilling rush of hydro-powered flight as it propels you above the water's surface. Revel in the breathtaking vistas of Calangute Beach or the serene Chapora River. This exhilarating activity is conducted under the expert guidance of certified trainers, ensuring a safe and memorable Flyboarding experience at Calangute Beach and Chapora River in Goa.</p>

<h3>About the Flyboarding in North Goa Activity:</h3>
<p>Arrive at the activity location 15 minutes prior to the scheduled start time for a seamless experience. After a comprehensive briefing by our instructors, prepare for an adrenaline-pumping Goa Flyboarding adventure. You will be equipped with jet propulsion, a safety helmet, and a life jacket to guarantee your safety throughout the experience.</p>
<p>The board is connected by a long hose, propelling riders up to heights of approximately 30 feet above the water's surface. Feel the exhilaration as you soar above the sea for an impressive 10 to 15 minutes. The hydro-powered flight is expertly regulated by a trained instructor to ensure a secure and enjoyable experience.</p>
<p>Additionally, the jet propeller provides you with the opportunity to plunge into the pristine waters, allowing you to explore the depths below.</p>

    </ol>,
    link: 'https://wa.me/918171669057?text=hello%20%2C%20its%20great%20to%20know%20about%20your%20website%20scubadive.org.in%20.%20I%20am%20interested%20for%20booking%20flyboarding%20package%20%2C%20kindly%20send%20me%20the%20details%3F',

  },
/*cart7*/
  {
    imageUrl: './images/bungee.jpg',
    title: 'bungee jumping in goa',
    Book: 'Book Now',

    description: <ol style={{ textAlign: 'justify' }}>
      <h3>Package Details</h3>
<li><b>Location</b>:Vadaval , latambercem near sal 403504</li>
<li><b>Age</b>:12 to 50 yr old , after 50 needs medical certificate</li>
<li><b>Timing</b>: 10:00 AM to 4:30 PM</li>
<li><b>Attire</b>: shoes and trousers are recommended</li>
<li><b>Includes</b>: DSLR video and pics , jump certificate include</li>
<li><b>Drone shot</b>: extra price 999 Rs (if needed)</li>
    </ol>,
    link: 'https://wa.me/918171669057?text=hello%20%2C%20its%20great%20to%20know%20about%20your%20website%20scubadive.org.in%20.%20I%20am%20interested%20for%20booking%20bungeejumping%20package%20%2C%20kindly%20send%20me%20the%20details%3F',

  },
  /*cart8*/
  {
    imageUrl: './images/img19.jpg',
    title: 'North goa sightseeing package',
    Book: 'Book Now',

    description: <ol style={{ textAlign: 'justify' }}>
      <h3>Package Details</h3>
      <li><b>Morning Pickup at 8:30 am</b> from the fixed pickup point in <b>Panjim</b>, <b>Calangute</b>, <b>Baga</b>, <b>Arpora</b>, <b>Candolim</b>, <b>Senquerim</b>.</li>

<li><b>CALANGUTE TO FORT AGUADA:</b>
A tour that begins with a visit to the <b>Famous Fort Aguada</b> in Candolim. This 17th Century fort is now made even more famous by the 5-star Taj hotel complex. Fort Aguada in Goa is one of the most important forts in Goa and the most crucial of all forts in Goa. Today, it is being used as a Goa jail. It was built by the Portuguese government in 1612 to protect Old Goa from enemy attacks. A spring within the Aguada fort supplied water to all the ships that called there.
</li>

<li><b>SALIGAO CHURCH:</b>
Unique in its architectural design and illuminated throughout the tourist season, <b>Saligao Church</b> is one of the most beautiful churches in Goa. It is the Parish Church for the Saligao village, only 4 kms from the world-famous beach Calangute. The Goa Tourism Department has made arrangements to illuminate the church at night. It looks like a shimmering jewel in the fields as you drive by on the Chogum road from Panjim to Calangute.
</li>

<li><b>CALANGUTE BEACH:</b>
The trip rounds off with a visit to <b>Calangute Beach</b>, widely known as the queen of Goa’s beaches. Picnics and musical groups draw hundreds of people. Plenty of water sports activities can be seen at Calangute. Calangute has a number of luxury and budget hotels, attracting thousands of visitors from all parts of the world every year.
</li>

<li><b>ANJUNA BEACH (Viewpoint):</b>
<b>Anjuna Beach</b> is very popular among foreigners and is a beautiful picnic spot, known for Trance parties and the Hippie culture of the 60s and 70s. It's famous for its vibrant nightlife and is always crowded with foreign tourists. Today, the main attraction of Anjuna Beach is the Wednesday market, popularly known as the “Flea Market”.
</li>

<li><b>VAGATOR BEACH:</b>
<b>Vagator Beach</b> is famous for its seclusion with the Chapora Fort as its imposing backdrop. It is undeniably one of the best beaches in the world. Vagator Beach is split into two main beaches by a seaside headland which holds the car park and lots of stalls selling trinkets, clothes, soft drinks, and snacks. As you face the sea, on your right is North Vagator Beach (Big Vagator), and on your left Ozram Beach, more commonly known as Little Vagator Beach.
</li>

<li><b>MORJIM BEACH:</b>
<b>Morjim Beach</b> is an isolated beauty in North Goa where you will find a majority of Russian tourists. The beach stretches of empty sand with lots of palms and casuarina trees in the background. You can spot some beautiful birds around.
</li>

<li><b>ASHVEM BEACH:</b>
<b>Ashvem Beach</b> is a rocky yet beautiful beach in the north of Goa. Many locals visit this beach for their family picnics.
</li>

<li>Return back to the hotel by <b>4:30 pm</b>.</li>

    </ol>,
    link: 'https://wa.me/918171669057?text=hello%20%2C%20its%20great%20to%20know%20about%20your%20website%20scubadive.org.in%20.%20I%20am%20interested%20for%20booking%20north+goa+sightseeing%20package%20%2C%20kindly%20send%20me%20the%20details%3F',

  },

  /*cart9*/
  {
    imageUrl: './images/img21.jpg',
    title: 'South goa sightseeing in goa',
    Book: 'Book Now',

    description: <ol style={{ textAlign: 'justify' }}>
      <h3>Package Details</h3>
      <li><b>Pickup from 08:30 am to 09:30 am</b> at the Calangute fixed point closest to your hotel or resort.</li>

<li><b>CALANGUTE TO MIRAMAR BEACH:</b>
  A short but charming beach, home to the Samadhi of Goa's first Chief Minister, Late Dayananda Bandodkar. Located on the way to Dona Paula and just 3 km from Panjim, the capital city of Goa, this beach is popularly known as "Gasper Dias."
</li>

<li><b>DONA PAULA:</b>
  From its high point, you can witness the mesmerizing sight of the River Zuari merging with the Arabian Sea. Dona Paula Beach, situated on the Arabian Sea, is a secluded bay and one of the most sought-after beaches in Goa. Water sports facilities are a major attraction here. Once a quiet village, it is now one of the most commercialized areas in Goa. The beach is named after the daughter of one of the Viceroys of India, Dona Paula de Menzes, who leaped off the cliff because she was not permitted to marry a local fisherman. This beach is steeped in mysticism and divine beauty.
</li>

<li><b>BOM JESUS OF BASILICA (Old Goa Churches):</b>
  Here lies the remains of St. Francis Xavier's body. The Basilica of Bom Jesus is a World Heritage Monument and one of the finest baroque churches in Goa. The name of Jesus is symbolically represented by the letters 'IHS' and is found embellished throughout the Basilica.
</li>

<li><b>ST. CATHEDRAL:</b>
  One of the most ancient and celebrated religious buildings in Goa, this magnificent 16th-century monument to Roman Catholic rule in Goa under the Portuguese is the largest church in Asia and the largest of its kind in the East.
</li>

<li><b>MANGUESHI TEMPLE:</b>
  Dedicated to Lord Shiva, this temple was built in the 17th Century.
</li>

<li><b>SHANTADURGA TEMPLE:</b>
  One of the wealthiest temples dedicated to Shantadurga, the goddess of peace. It is also famous for its golden palanquin.
</li>

<li><b>RIVER CRUISE (BOAT CRUISE):</b>
  Enjoy the lively culture of Goa with songs and dance during a river cruise.
</li>

<li>Drop back to your hotel after the boat cruise, expected by <b>7:00 pm</b>.</li>

    </ol>,
    link: 'https://wa.me/918171669057?text=hello%20%2C%20its%20great%20to%20know%20about%20your%20website%20scubadive.org.in%20.%20I%20am%20interested%20for%20booking%20south+goa+sightseeing%20package%20%2C%20kindly%20send%20me%20the%20details%3F',

  },

 /*cart end here*/




];

function HotDeals() {
  return (
    <div>
      <Scubabg />
      <div className="container mt-4">
        <div className="row">
          {cardContent.map((card, index) => (
            <div className="col-lg-4 col-md-6 mb-4" key={index}>
              <div className="card">
                <div className="position-relative">
                  <img src={card.imageUrl} className="card-img-top" alt="scuba dive in goa" />
                  <div className="overlay"></div>
                </div>
                <div className="card-body">
                  <h5 className="card-title custom-center">
                    {card.title} <hr /><h6>Booking Amount : 500 INR/Person</h6><hr />
                    <a onclick="goog_report_conversion ('href={card.link}')" href={card.link} className="btn btn-primary" style={{ backgroundColor: 'blue ' }}>{card.Book}</a>

                  </h5>
                  <div className="card-text custom-scrollbar">
                    {card.description}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default HotDeals;















