import React from 'react'

const Payment = () => {
  return (
    <div>
    <ul>
    <h3><li>Safe and Secure Payment Gateway (Razorpay) </li></h3>
      <p>Banking Name : ANKIT KUMAR</p>
    <hr/>
    <a href="https://razorpay.me/@bookingmytrip" className="btn btn-primary">Pay Now</a>
    <br/><hr/>
    <h3><li>We Accept Payment By</li></h3>
    <li>UPI (PhonePe , Google Pay , PayTm or any other UPI App , UPI ID/Mobile Number)</li>
    <li>Credit card or debit card</li>
    <li>Net Banking (All Bank)</li>
    <li>Wallet ( Mobikwik , Freecharge , Airtel Payments Bank , Ola Money , jio money )</li>
    <li>Pay your booking amount for seat booking for adventure activity ,After payment please share your payment screen shot to our whatsapp number(+917017751245 or +918171669057) so that we can generate your booking reciept.</li>
    <li>Please write your Name ,Trip date and Number of person in payment description/notes or directly tell us on Whatsapp.</li><br/><hr/>
   <li>Customer Care support : Whatsapp or Call (+917017751245 or +918171669057)</li>
   <li>Email us : scubadive.org.in@gmail.com , support@scubadive.org.in (Use Gmail or Whatsapp for faster response)</li><br/>
    <a href="https://wa.me/917017751245?text=Hello%20from%20Scubadive.org.in!%20I%20would%20like%20to%20know%20more%20about%20your%20packages." className="btn btn-primary">Chat on Whatsapp</a>
    <hr/>
      </ul>
      
      
    </div>
  )
}

export default Payment
