import React from 'react';

function Refund() {
  return (
    <div>
      <h1>Refund Policy</h1>
      <p>
        We appreciate your interest in our scuba diving and water sports services in Goa. Our commitment is to provide you with the finest experiences possible. In line with our dedication to customer satisfaction, we've established a refund policy that emphasizes transparency and fairness for all our valued clients.
        <hr />

        Refund Policy:
        <b> We understand that unforeseen circumstances can occur, necessitating the cancellation of your booking. In such cases, we offer refunds only if the cancellation is made at least one day before from activity day till 9pm . Cancellations on same day(activity day) will not be refunded.</b>
        <hr />

        <b>
          <ol>
            <li>No refund for same-day bookings.</li>
            <li>Refunds for advance bookings will only be issued if we are informed one day before the scheduled activity or reporting day.</li>
            <li>In the event of activity cancellation due to natural calamities such as heavy rainfall, earthquakes, or sea cyclones, a full refund will be provided to the customer within 3 days.</li>
            <li>If an advance booking is made but the customer fails to report on the scheduled day, no refund will be issued. Shifting the booking to the next day incurs an extra charge of 100 INR per person.</li>
          </ol>
          <hr />
        </b>

        The boat seat booking amount and food costs must be paid to our partners per person. If you cancel suddenly, our money and reserved seats go to waste. For this reason, if you wish to cancel, please do so before the trip date or, in the case of a date change, inform our customer support one day in advance.
        <br />
        In case of natural disasters or high tide, certain activities may be prohibited on the trip date. Therefore, no refund will be provided due to any government order related to activity closure. For activities, you must coordinate on-site with your trip coordinator.
        <br />
        If you do not reach the pickup location on time and miss your pickup, it is your responsibility. No refund will be given because there are multiple pickups from various locations, and we cannot wait for an extended period. Please ensure you are present at the designated pickup location on time.

        To request a refund, please contact our customer service team via phone or email, providing your booking details and the reason for cancellation. We will process your refund within 7 business days from the date of cancellation.

        Please note that we reserve the right to deny a refund if the cancellation request is made after the 24-hour window or if the client fails to show up for their booking.

        We take pride in delivering top-notch services and ensuring customer satisfaction. If you have any questions or concerns regarding our refund policy or services, please don't hesitate to get in touch.

        Thank you for selecting our scuba diving and water sports services. We look forward to serving you soon.
      </p>
      <li>if any injury or mishappenning occur during activity or travelling time , we will not be responsible for your loss , scubadive.org.in will not pay any amount in such case , you will be responsible for your own so always wear life jacket during activity and take care yourself</li>
    </div>
  );
}

export default Refund;
