import React from 'react';
import './About.css';
import scubaDivingImage from './images/scubadivegoa.png'; // Import your image

export default function About() {
  return (
    <>
      <div className="about-section">
      <div className="about-image-container">
        <img src={scubaDivingImage} alt="Scuba Diving in Goa" className="about-image" />
        </div>
        <h1>Welcome to Scubadive.org.in</h1>
        <hr />
        <p>
          Scubadive.org.in is your premier destination for scuba diving and water sports adventures in the beautiful coastal paradise of Goa.
        </p>
        <p>
          Our dedicated team of passionate professionals is committed to providing you with extraordinary experiences beneath the waves and above the surface.
        </p>
        <p>
          Explore the mesmerizing underwater world of Goa with confidence, guided by our highly trained and certified instructors. Your safety and comfort are our top priorities.
        </p>
        <p>
          We offer a diverse range of scuba diving and water sports activities, including snorkeling, jet skiing, parasailing, banana boat rides, and more. Whether you're a beginner or an experienced diver, we have tailor-made packages to suit your preferences.
        </p>
        <p>
          Our state-of-the-art equipment is regularly serviced and maintained to ensure optimal safety and performance. We are committed to eco-friendly practices to protect the environment while providing thrilling adventures.
        </p>
        <p>
          At Scubadive.org.in, we are driven by customer satisfaction and strive to exceed your expectations. Our friendly and knowledgeable staff are here to answer your questions and help plan your perfect scuba diving and water sports adventure.
        </p>
        <p>
          Join us for an unforgettable journey into the depths of the Arabian Sea. Your scuba diving and water sports adventure in Goa awaits!
        </p>
        <hr />
        <div className="contact-info">
          <p>For inquiries and bookings:</p>
          <p>WhatsApp & Call: +917017751245, +918171669057</p>
          <p>Email: support@scubadive.org.in</p>
        </div>
        <a href="https://wa.me/917017751245?text=Hello%20from%20Scubadive.org.in!%20I%20would%20like%20to%20know%20more%20about%20your%20packages." className="btn btn-primary">Chat on WhatsApp</a>
      </div>
    </>
  );
}
