import React from 'react';
import './About.css';
import contactImage from './images/scubadivegoa.png'; // Import your image

export default function Contact() {
  return (
    <div>
      <div className="about-section">
        <div className="about-image-container">
          <img src={contactImage} alt="Scuba Diving in Goa" className="about-image" />
        </div>
        <h1>Contact Us</h1>
        <hr />
        <p>
          Thank you for considering Scubadive.org.in for your scuba diving and water sports adventures in the captivating region of Goa.
        </p>
        <p>
          We value your inquiries, comments, and feedback. Whether you have questions about our services or simply want to share your thoughts, we're here to listen and assist.
        </p>
        <p>
          You can reach us through various channels:
        </p>
        <ul><hr/>
          <li>
            Phone: +917017751245, 8171669057
          </li><hr/>
          <li>
            Email: support@scubadive.org.in
          </li>
          <li><hr/>
            YouTube: <a href="https://www.youtube.com/@scubadivegoa" className="btn btn-primary">Visit our YouTube Channel</a>
          </li><hr/>
          <li>
            Instagram: <a href="https://www.instagram.com/invites/contact/?i=dviy6kiqeox6&utm_content=r9oqp3p" className="btn btn-primary">Follow us on Instagram</a>
          </li><hr/>
        </ul>
        <p>
          We're excited to connect with you and assist you in planning your next unforgettable adventure in Goa!
        </p>
        <br />
        <p>
          Office Address: Car Parking, Calangute, Goa (Pin: 403516)
          <br /><hr/>
          WhatsApp & Call Number: +917017751245
          <br />
          Email: support@scubadive.org.in
        </p>
        <p>Want to chat with us on WhatsApp? Click below:</p>
        <a href="https://wa.me/917017751245?text=Hello%20from%20Scubadive.org.in!%20I%20have%20some%20questions%20about%20your%20services." className="btn btn-primary">Chat on WhatsApp</a>
      </div>
    </div>
  );
}
